app.directive('surveyMenu',
  ['$location', '$routeParams', 'portalHelper', 'menuHeaderService', function ($location, $routeParams, portalHelper, menuHeaderService) {
    return {
      scope: {
        isNew: '=',
        step: '=',
        surveyId: '@'
      },
      templateUrl: portalHelper.getUrlRev('html_templates/directives/client_survey_menu.2c6c27c0.html'),
      controller: function ($scope) {
        var step_classes = [];

        $scope.getStepClass = function (step_num) {
          return step_classes[step_num - 1];
        };

        $scope.isAdminPortal = $location.absUrl().indexOf('admin/#/Admin') !== -1;

        if ($scope.isAdminPortal) {
          menuHeaderService.fetchClients().then(
            function (clientsList) {
              $scope.surveyOwner = _.find(clientsList,
                { 'id': parseInt($routeParams.clientUserId) });
            }
          );
        }

        $scope.goToStep = function (step_num) {
          if (step_classes[step_num - 1].indexOf('disabled') !== -1) {
            return;
          }

          var redirectUrlCreateProject = 'Client/CreateProject/0';
          var redirectUrlUpdateProject = 'Client/CreateProject/' + $scope.surveyId;
          var redirectUrlManageQuestions = 'Client/ManageQuestions/' + $scope.surveyId;
          var redirectUrlViewSurvey = 'Client/ViewSurvey/' + $scope.surveyId + '/draft';
          var redirectUrlDisclaimers = 'Client/Survey/' + $scope.surveyId + '/Disclaimers';

          if (step_num === 1) {
            if ($scope.isNew) {
              $location.path(redirectUrlCreateProject);
            } else {
              $location.path(redirectUrlUpdateProject);
            }
          } else if (step_num === 2) {
            $location.path(redirectUrlDisclaimers);
          }  else if (step_num === 3) {
            $location.path(redirectUrlManageQuestions);
          } else if (step_num === 4) {
            $location.path(redirectUrlViewSurvey);
          }
        };

        if ($scope.isNew) {
          step_classes = ['activeStep', 'disabledStep', 'disabledStep', 'disabledStep'];
        } else {
          for (var i = 1; i <= 4; i++) {
            if (i < $scope.step) {
              step_classes.push('completedStep');
            } else if ($scope.step === i) {
              step_classes.push('activeStep');
            } else {
              step_classes.push('');
            }
          }
        }
      }
    };
  }]
);
