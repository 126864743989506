var app = angular.module('MatApp',
  ['MatRouting', 'ngRoute', 'ngSanitize', 'ui.select', 'angularjs-dropdown-multiselect', 'mat-angularjs-dropdown-multiselect', 'ngTagsInput', 'ui.bootstrap']);

angular.module('MatRouting', ['ngRoute', 'cp.ngConfirm']).config(function ($routeProvider) {
  $routeProvider
    .when('/', {
      controller: 'redirectController',
      template: '',
      targetURL: 'login/#/Login'
    })
    .when('/Login', {
      controller: 'redirectController',
      template: ''
    })
    .when('/PayerExpertiseForm/:formId/:existingPayer/:token', {
      template: '<payer-expertise-form />'
    })
    .when('/Login/Return/:returnUrl', {
      controller: 'redirectController',
      template: '',
      targetURL: 'login/#/Login/Return/:returnUrl'
    })
    .when('/open_document/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Forgotten', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Reset/:token', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Administration', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/PayerFAQs', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/PayerFAQ/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Log/LoginAttempts', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ClientFAQs', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ClientFAQ/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/SurveysInDevelopment', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/DiseaseAreas', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/DiseaseAreas/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/FunctionalExpertise/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/FunctionalExpertise', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Payer/Edit/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Payer/Edit/:id/:afterSaveRedirect', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Payer/EditImported/:id/:crm_user_id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/PageContent', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/PageContent/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/QuestionBank', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/QuestionBank/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Users', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Users/Edit/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Clients/Edit/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Clients/ClientEdit/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Clients/ClientEdit/:id/:afterSaveRedirect', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ClientUsers/Edit/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Clients/ClientUserEdit/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ClientUsers', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Clients', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/PayerStatistics/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/OpenSurveys', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ResponseApproval?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ResponseApproval/Survey/:id?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ExportResponses/Survey/:id?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ViewSurveyPreview/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/AllSurveys', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/LiveSurveys', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/PayerApproval/:id/:prev_page?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Survey/:id/:prev_page?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ClarificationRequests', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ClarificationResponses', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ViewSurveyClarifications/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ViewSurveyClarificationResponse/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/GlobalScales', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/RatingScale/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/SliderRatingScale/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/DragDropRanking/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/RadioButtonRanking/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/CompletedSurveysReport', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Licences', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Clients/Licences', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Licence/Edit/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Payer/AdditionalFees/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Payer/Expertise/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/CreditAllocation/:client_id/:licence_id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ImportPayers', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ManageQuestions/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/ManageQuestions/:id/:clientUserId/Country/:country_id/question/:question_id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Licence/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Licence/Upgrade/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Licence/Transfer/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/Licence/ConfirmCreditsTransfer/:id', {
      controller: 'redirectController',
      templateUrl: ''
    })
    .when('/Help', {
      controller: 'redirectController',
      template: ''
    })
    .when('/FAQs', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/InDevelopment', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/InProgress', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/CompletedSurveys', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/ViewSurveyPreview/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/ViewSurvey/:id/:status', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/TestSurvey/:id/:country_id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/CreateSurvey/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/ManageQuestions/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/ManageQuestions/:id/:clientUserId/Country/:country_id/question/:question_id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/RatingScale/:id/:survey_id?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/DragDropRanking/:id/:survey_id?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/RadioRanking/:id/:survey_id?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/RankingScale/:id/:survey_id?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/RadioRankingScale/:id/:survey_id?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/TableOfResponses/:id/:survey_id?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/TableOfResponsesAfterClone/:id/:survey_id?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/SurveyDeleted/', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/SentForApproval/', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/AlreadySubmitted', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/SurveyDescription/:survey_id/:country_id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Client/ViewResponses/:id/:from?', {
      controller: 'redirectController',
      template: ''
    })
    .when('/SurveyDoesNotExist', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/NewSurveys', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/ViewSurvey/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/MyAccount', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/Disclaimer/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/SurveyDescription/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/ProgressSurveys', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/SurveySubmitted', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/AlreadySubmitted/', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/Clarifications', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/PayerExpertiseForm/:formId/:existingPayer/:token', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/PayerExpertiseForm/:formId/:existingPayer/:token/:payerId', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/ViewRequest/:id', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Payers/MyProfile', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/CreateBills', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/quickbooks-oauth', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/about-rpr', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/AdvancedPayerSelection', {
      controller: 'redirectController',
      template: ''
    })
    .when('/Admin/AdvancedSearch', {
      controller: 'redirectController',
      template: ''
    })
    .otherwise({
      redirectTo: '/Login'
    });
});
