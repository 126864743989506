app.directive('surveyResponsesSidebar',
  ['$location', '$ngConfirm', 'apiHelper', 'portalHelper', 'CONSTANTS',
    function ($location, $ngConfirm, apiHelper, portalHelper, CONSTANTS) {
      return {
        scope: {
          surveyDetails: '='
        },
        templateUrl: portalHelper.getUrlRev(
          'html_templates/directives/survey_responses_sidebar.6f550b25.html'),
        controller: function ($scope) {

          $scope.date_only_format = portalHelper.getDateOnlyFormat();
          $scope.api_url = apiHelper.getApiUrl();
          $scope.surveyStatuses = CONSTANTS.surveyStatuses;
          $scope.surveyStatusNames = portalHelper.surveyStatusNames;
          $scope.CONST = CONSTANTS;

          $scope.download_report = function (report_id) {
            portalHelper.downloadFileWithUrl(
              '/api/client/survey/report/' + report_id + '.json'
            );
          };

        }
      };
    }]
);

